/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useRef, useEffect } from 'react';
import { URLConfig } from '../../utils/config';
import { PDLocObjType } from '../../types/schema/utils.types';
import {
  AdvancedMarker,
  APIProvider,
  ControlPosition,
  InfoWindow,
  Map,
  MapControl,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';

const GoogleMapsDisplay: React.FC<{
  business_address: PDLocObjType;
}> = ({ business_address }) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  const center = {
    lat: business_address.cords[1],
    lng: business_address.cords[0],
  };

  return (
    <APIProvider apiKey={URLConfig.mapsGeocodingAPIKey!}>
      <Map
        mapId={URLConfig.mapsId}
        style={{ width: '100%', height: '450px', borderRadius: 10 }}
        defaultCenter={center}
        center={center}
        defaultZoom={11}
        gestureHandling={'greedy'}
        disableDefaultUI={true}>
        <AdvancedMarker position={center} ref={markerRef} />
        <InfoWindow anchor={marker}>
          {business_address.location_name}
        </InfoWindow>
        <MapControl position={ControlPosition.TOP_LEFT}></MapControl>
      </Map>
    </APIProvider>
  );
};

export default GoogleMapsDisplay;
