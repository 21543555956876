import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import './BusinessPageAllEnquiriesPage.scss';
import { useNavigate } from 'react-router-dom';
import EnquiryListItem from '../../components/Enquiry/EnquiryListItem';
import { gql } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import CustomButton from '../../components/UI/CustomButton';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import Colors from '../../constants/Colors';
import { useGetAllBpenquiriesByCustomerId } from '../../hooks/gql/query/getAll/useGetAllBpenquiryByCustomerId';

const GET_ALL_BPENQUIRIES_BY_ID = gql`
  query GetAllBpenquiriesByCustomerId($input: CustomerBpenquiryInput!) {
    getAllBpenquiriesByCustomerId(input: $input) {
      _id
      status
      trip_info {
        pickup_datetime
        pickup_location {
          cords
          location_name
        }
        destination {
          cords
          location_name
        }
        return_datetime
        passenger_count
        travelling_reason
        tripType
        vehicle_type
      }
      proposals {
        _id
      }
    }
  }
`;

const BusinessPageAllEnquiriesPage: React.FC<{}> = (props) => {
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const [domain, setDomain] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    const urlObject = new URL(window.location.href);
    const domainRegex = new RegExp('businesspage\\/([^/]+)');
    const match = urlObject.pathname.match(domainRegex);

    if (match) {
      setDomain(match[1]);
    }
  }, []);

  const [page_number, setPage_number] = useState(1);
  const [sortOption, setSortOption] = useState(
    'accepting_offer-offer_accepted'
  );

  const {
    data: enquiries,
    error: enquiriesError,
    loading: enquiriesLoading,
    refetch: enquiriesRefectch,
  } = useGetAllBpenquiriesByCustomerId(GET_ALL_BPENQUIRIES_BY_ID, {
    customer_ref: user._id || '64f8267c7ed1f6aa0166bde1',
    domain_name: domain,
    page: page_number,
    item_per_page: 20,
    status: sortOption,
  });

  useEffect(() => {
    enquiriesRefectch();
  }, [domain]);

  return (
    <>
      <div className='sorting_wrapper'>
        <p>Sort by</p>
        <FormControl style={{ marginLeft: 20 }}>
          <Select
            id='sort-simple-select'
            value={sortOption}
            onChange={(event) => {
              setSortOption(event.target.value as string);
              enquiriesRefectch();
            }}
            sx={{
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            }}>
            <MenuItem value={'accepting_offer-offer_accepted'}>
              All Enquiries
            </MenuItem>
            <MenuItem value={'accepting_offer'}>In Progress</MenuItem>
            <MenuItem value={'offer_accepted'}>Completed</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className='bpenquiries_wrapper'>
        {isAuthenticated ? (
          <>
            {enquiriesLoading && <LoadingSpinner />}
            {enquiries?.length === 0 && (
              <div className='no_bpenquiries'>
                <p>Oops! No Enquiries Found...</p>
              </div>
            )}
            {enquiries &&
              enquiries.length > 0 &&
              enquiries.map((enq) => {
                return (
                  <EnquiryListItem
                    key={enq._id}
                    _id={enq._id}
                    trip_info={enq.trip_info}
                    status={enq.status}
                    proposalsCount={enq.proposals?.length || 0}
                    onClick={(_id: string) => {
                      navigate(`/businesspage/${domain}/all-enquiries/${_id}`);
                    }}
                  />
                );
              })}
          </>
        ) : (
          <>
            <div className='no_enquiries'>
              <p>
                Initiate Booking/Provide information in{' '}
                <strong
                  onClick={() => navigate(`/businesspage/${domain}`)}
                  style={{
                    cursor: 'pointer',
                    color: Colors.primaryColorLight,
                  }}>
                  Profile Page
                </strong>{' '}
                to See this Page!
              </p>
            </div>
          </>
        )}
      </div>

      {isAuthenticated && (
        <div className='bpenquiries_action_wrapper'>
          <CustomButton
            title='Load More'
            onClick={() => {
              setPage_number((pn) => pn + 1);
              enquiriesRefectch();
            }}
            isLoading={enquiriesLoading}
          />
        </div>
      )}
    </>
  );
};

export default BusinessPageAllEnquiriesPage;
