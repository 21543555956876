import { URLConfig } from './config';

export const businesspage_sections = [
  'image_section',
  'form_section',
  'review_section',
  'location_section',
];

export const businesspage_default_images = [
  '/images/bp/1.jpg',
  '/images/bp/2.jpg',
  '/images/bp/3.jpg',
  '/images/bp/4.jpg',
  '/images/bp/5.jpg',
];
