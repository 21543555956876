import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './BusinessPageNavigation.scss';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { URLConfig } from '../../utils/config';

const BusinessPageNavigation: React.FC<{
  domain?: string;
  logoUrl?: string;
}> = ({
  domain,
  logoUrl = `${URLConfig.customerWebUrl}/images/main_logo.png`,
}) => {
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className='navbar'>
      <div className='container'>
        <div className='logo'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              minWidth: 50,
            }}>
            <img
              onClick={() => {
                navigate(`/businesspage/${domain}`);
              }}
              className='logo_desk'
              style={{
                width: 50,
                cursor: 'pointer',
                borderRadius: 100,
              }}
              src={logoUrl}
              alt='Logo'
            />

            <img
              className='logo_mob'
              style={{
                width: 35,
                borderRadius: 100,
                cursor: 'pointer',
                background: '#fff',
                boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
              }}
              src={logoUrl}
              alt='Logo'
            />
          </div>
        </div>
        <div className='menu-icon' onClick={handleShowNavbar}>
          {!showNavbar ? <MenuIcon /> : <CloseIcon />}
        </div>
        <div className={`bp_nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <NavLink to={`/businesspage/${domain}`}>Profile</NavLink>
            </li>
            <li>
              <NavLink to={`/businesspage/${domain}/all-enquiries`}>
                All Enquiries
              </NavLink>
            </li>

            <li>
              <NavLink to={`/businesspage/${domain}/my-operators`}>
                My Operators
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default BusinessPageNavigation;
