import './BusinessPageMyOperatorsPage.scss';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import CustomButton from '../../components/UI/CustomButton';
import OperatorsListItem from '../../components/ListItem/OperatorsListItem';
import { useGetAllOperatorsByBpenquiriesCustomerId } from '../../hooks/gql/query/getAll/useGetAllOperatorsByBpenquiriesCustomerId';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import Colors from '../../constants/Colors';

const GET_ALL_MY_OPERATORS = gql`
  query GetAllOperatorsByBpenquiriesCustomerId(
    $input: OperatorsBpenquiryInput!
  ) {
    getAllOperatorsByBpenquiriesCustomerId(input: $input) {
      _id
      company_name
      referral_code
      contact_name
      domain_name
      email
      phone
      photo
      isPhotoVisible
      vehiclePictures
      isVPVisible
      business_address {
        location_name
      }
    }
  }
`;

const BusinessPageMyOperatorsPage: React.FC<{}> = (props) => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.auth
  );

  const [domain, setDomain] = useState<string>('');
  const [page_number, setPage_number] = useState(1);
  const navigate = useNavigate();

  const {
    data: users,
    error: usersError,
    loading: usersLoading,
    refetch: usersRefectch,
  } = useGetAllOperatorsByBpenquiriesCustomerId(GET_ALL_MY_OPERATORS, {
    customer_ref: user._id || '672db0e5aa5c24ddcbe223a7',
    page: page_number,
    item_per_page: 9,
  });

  useEffect(() => {
    const urlObject = new URL(window.location.href);
    const domainRegex = new RegExp('businesspage\\/([^/]+)');
    const match = urlObject.pathname.match(domainRegex);

    if (match) {
      setDomain(match[1]);
    }
  }, []);

  return (
    <>
      <div className='main_operators_cards'>
        {usersLoading && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '50px',
            }}>
            <LoadingSpinner />
          </div>
        )}

        {isAuthenticated && users && users.length === 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '50px',
            }}>
            <p>No operators found</p>
          </div>
        )}

        {!isAuthenticated && users && users.length === 0 && (
          <div className='no_enquiries'>
            <p>
              Initiate Booking/Provide information in{' '}
              <strong
                onClick={() => navigate(`/businesspage/${domain}`)}
                style={{
                  cursor: 'pointer',
                  color: Colors.primaryColorLight,
                }}>
                Profile Page
              </strong>{' '}
              to See this Page!
            </p>
          </div>
        )}
        {users &&
          users?.map((u) => {
            return (
              <OperatorsListItem
                key={u._id}
                _id={u._id}
                company_name={u.company_name}
                referral_code={u.referral_code}
                contact_name={u.contact_name}
                email={u.email}
                phone={u.phone}
                photo={u.photo}
                isPhotoVisible={u.isPhotoVisible}
                vehiclePictures={u.vehiclePictures}
                isVPVisible={u.isVPVisible}
                business_address={u.business_address}
                onActionClick={() => {
                  navigate(`/businesspage/${u.domain_name}`);
                }}
              />
            );
          })}
      </div>

      {isAuthenticated && (
        <div className='operators_action_wrapper'>
          <CustomButton
            title='Load More'
            onClick={() => {
              setPage_number((pn) => pn + 1);
              usersRefectch();
            }}
            isLoading={usersLoading}
          />
        </div>
      )}

      <div className='footer_content'></div>
    </>
  );
};
export default BusinessPageMyOperatorsPage;
