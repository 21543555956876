import { useSelector } from 'react-redux';
import {
  useQuery,
  DocumentNode,
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { RootState } from '../../../../store/store';
import {
  Bpenquiry,
  CustomerBpenquiryInput,
} from '../../../../types/schema/bpenquiry.types';

interface GetAllBpenquiriesByCustomerIdHook {
  data: Bpenquiry[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      getAllBpenquiriesByCustomerId: Bpenquiry[];
    }>
  >;
}

interface UseGetAllBpenquiriesByCustomerIdMutationData {
  getAllBpenquiriesByCustomerId: Bpenquiry[];
}

interface UseGetAllBpenquiriesByCustomerIdInputType {
  input: CustomerBpenquiryInput;
}

export function useGetAllBpenquiriesByCustomerId(
  query: DocumentNode,
  input: CustomerBpenquiryInput
): GetAllBpenquiriesByCustomerIdHook {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const { data, loading, error, refetch } = useQuery<
    UseGetAllBpenquiriesByCustomerIdMutationData,
    UseGetAllBpenquiriesByCustomerIdInputType
  >(query, {
    variables: {
      input,
    },
    context: {
      headers: {
        cookie: `jwt=${jwt}`,
        authorization: jwt ? `Bearer ${jwt}` : '',
      },
    },
  });

  return {
    data: data?.getAllBpenquiriesByCustomerId,
    loading,
    error,
    refetch,
  };
}
