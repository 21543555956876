import { getNameAvatar } from '../../utils/functions';
import './OperatorsListItem.scss';
import { Avatar, FormControl } from '@mui/material';
import Colors from '../../constants/Colors';
import Button from '@mui/material/Button';
import { gBaseUrl } from '../../utils/config';
import { PDLocObjType } from '../../types/schema/utils.types';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const OperatorsListItem: React.FC<{
  _id: string;
  company_name: string;
  referral_code: string;

  contact_name: string;
  email: string;
  phone: string;

  photo: string;
  isPhotoVisible: boolean;

  vehiclePictures?: string[];
  isVPVisible: boolean;
  business_address?: PDLocObjType;

  onActionClick: () => void;
}> = ({
  _id,
  company_name,
  referral_code,
  contact_name,
  email,
  phone,
  photo,
  isPhotoVisible,
  vehiclePictures,
  isVPVisible,
  business_address,
  onActionClick,
}) => {
  return (
    <div className='operators_cards_item'>
      <div className='uac_content'>
        <div className='title_logo'>
          <div className='logo_comp'>
            {isPhotoVisible ? (
              <Avatar
                src={`${gBaseUrl}/photos/avatars/${photo}`}
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                }}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                }}>
                {getNameAvatar(contact_name)}
              </Avatar>
            )}
            <div className='logo_comp_det'>
              <p className='business_name'>{company_name}</p>
              <p className='contact_name'>ID: #{referral_code}</p>
            </div>
          </div>
        </div>

        <div className='operator_info'>
          <div className='operator_info_comp'>
            <div className='operator_info_comp_det'>
              <div className='contact_name_wrapper'>
                <AccountCircleOutlinedIcon color='secondary' />
                <h3 className='contact_name'>{contact_name}</h3>
              </div>

              <div className='email_wrapper'>
                <EmailOutlinedIcon color='secondary' />
                <p className='email'>{email}</p>
              </div>

              <div className='phone_wrapper'>
                <PhoneOutlinedIcon color='secondary' />
                <p className='phone'>{phone}</p>
              </div>

              <div className='address_wrapper'>
                <LocationOnOutlinedIcon color='secondary' />
                <p className='address'>{business_address?.location_name}</p>
              </div>
            </div>
          </div>
        </div>

        {isVPVisible && vehiclePictures && vehiclePictures.length > 0 && (
          <div className='picture_content'>
            <div className='content_wrapper'>
              {vehiclePictures.slice(-3).map((vp, i) => {
                return (
                  <div className='image_item' key={vp + i}>
                    <img src={`${gBaseUrl}/photos/vehiclePictures/${vp}`} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <div className='action_buttons'>
        <FormControl sx={{ width: '100%' }} variant='standard'>
          <Button
            variant='contained'
            color={'primary'}
            size='small'
            onClick={() => {
              onActionClick();
            }}>
            Business Page
          </Button>
        </FormControl>
      </div>
    </div>
  );
};

export default OperatorsListItem;
