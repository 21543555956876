import React, { useEffect, useState } from 'react';
import BusinessPageNavigation from './BusinessPageNavigation';
import BusinessPageFooter from './BusinessPageFooter';
import { useIsDomainExists } from '../../hooks/gql/mutation/action/useIsDomainExists';
import { gBaseUrl } from '../../utils/config';

const BusinessPageLayout: React.FC<{ children: React.ReactNode }> = (props) => {
  const [domain, setDomain] = useState<string | undefined>();
  const [logoUrl, setLogoUrl] = useState<string | undefined>();
  const { isDomainExists } = useIsDomainExists();

  const url = window.location.href;

  useEffect(() => {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const domainRegex = new RegExp('businesspage\\/([^\\/]+)');
    const match = pathname.match(domainRegex);
    if (match) {
      setDomain(match[1]);
    }
  }, [url]);

  useEffect(() => {
    const getDomainInformation = async (domain: string) => {
      try {
        const res = await isDomainExists({ text: domain });
        const user = res.isDomainExists;

        if (user && user.photo && user.isPhotoVisible) {
          setLogoUrl(`${gBaseUrl}/photos/avatars/${user.photo}`);
        }
      } catch (err) {
        //  Handle error
      }
    };

    if (domain) {
      getDomainInformation(domain);
    }
  }, [domain]);

  return (
    <>
      <BusinessPageNavigation domain={domain} logoUrl={logoUrl} />
      <main className='main'>
        <div className='main_content'>{props.children}</div>
      </main>
      <BusinessPageFooter domain={domain} />
    </>
  );
};

export default BusinessPageLayout;
