import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './HomePage.scss';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { gql } from '@apollo/client';
import {
  luggage_type_arr,
  travelling_reason_arr,
  vahicle_types_arr,
} from '../../utils/data';
import Colors from '../../constants/Colors';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { Button, Select as MUISelect, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import CustomButton from '../../components/UI/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import CustomerInfoDialog from '../../components/Dialog/CustomerInfoDialog';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { resetAuth, resetChecked } from '../../store/reducer/auth';
import Autocomplete from 'react-google-autocomplete';
import { URLConfig } from '../../utils/config';
import ReviewListItem from '../../components/ListItem/ReviewListItem';
import HtmlTooltip from '../../components/Utils/HtmlTooltip';
import { useAddEmptyReferral } from '../../hooks/gql/mutation/create/useAddEmptyReferral';
import { useUpdateReferral } from '../../hooks/gql/mutation/update/useUpdateReferral';
import { useGetAllReviewsByPage } from '../../hooks/gql/query/getAll/useGetAllReviewsByPage';
import { useIsReferralTokenExists } from '../../hooks/gql/mutation/action/useIsReferralTokenExists';
import { AddEmptyReferralInput } from '../../types/schema/referral.types';

const GET_REVIEWS_BY_PAGE = gql`
  query GetAllReviewsByPage($input: PaginationWithBooleanInput!) {
    getAllReviewsByPage(input: $input) {
      _id
      review_by {
        _id
        name
      }
      review_to {
        _id
        contact_name
        referral_code
        photo
        isPhotoVisible
      }
      message
      rating
      createdAt
    }
  }
`;

const HomePage: React.FC<{}> = (props) => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.auth
  );

  const [checked, setChecked] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openToolTip, setOpenToolTip] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const [pickupLocation, setPickupLocation] = useState<{
    location_name: string;
    cords: number[];
  }>({ location_name: '', cords: [] });
  const [pickupDateTime, setPickupDateTime] = useState<string>('');
  const [pickupSNote, setPickupSNote] = useState<string>('');
  const [dropLocation, setDropLocation] = useState<{
    location_name: string;
    cords: number[];
  }>({ location_name: '', cords: [] });
  const [dropSNote, setDropSNote] = useState<string>('');
  const [returnDateTime, setReturnDateTime] = useState<string>('');
  const [vehicleType, setVehicleType] = useState('0');
  const [messageOptional, setMessageOptional] = useState('');
  const [travellingReason, setTravellingReason] = useState('0');
  const [passengerCount, setPassengerCount] = useState('');
  const [luggageType, setLuggageType] = useState('0');
  const [vehicleCount, setVehicleCount] = useState('');

  const { addEmptyReferral, data: addEmptyReferralData } =
    useAddEmptyReferral();
  const { updateReferral, data: updateReferralData } = useUpdateReferral();
  const { isReferralTokenExists, data: isReferralTokenExistsData } =
    useIsReferralTokenExists();
  const {
    data: reviews,
    error: reviewsError,
    loading: reviewsLoading,
    refetch: reviewsRefectch,
  } = useGetAllReviewsByPage(GET_REVIEWS_BY_PAGE, {
    page: 1,
    item_per_page: 3,
    bool: true,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [isAuthenticated]);

  return (
    <div className='home_page_content_wrapper'>
      <div className='home_enquiry_section'>
        <div className='home_enquiry_section_form'>
          <div className='home_enquiry_section_form_content'>
            {isAuthenticated && (
              <div className='home_enquiry_section_form_content_greet'>
                <p
                  className='enq_home_display'
                  style={{ marginBottom: 10, color: 'rgb(129 135 139)' }}>
                  Already filled?{' '}
                  <span>
                    <a style={{ color: '#47C56C' }} href='/enquiries'>
                      View Enquiries
                    </a>
                  </span>
                </p>

                <div
                  style={{
                    opacity: 0.8,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <p style={{ marginRight: 10 }}>Welcome, {user.name}!</p>

                  <ClickAwayListener
                    onClickAway={() => {
                      setOpenToolTip(false);
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}>
                      <HtmlTooltip
                        placement='bottom-end'
                        open={openToolTip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        onClose={() => {
                          setOpenToolTip(false);
                        }}
                        title={
                          <React.Fragment>
                            <Typography
                              color='inherit'
                              style={{ fontSize: 14, fontWeight: 'bold' }}>
                              Confirm your Information
                            </Typography>
                            <div style={{ margin: '10px 0' }}>
                              <p style={{ fontSize: 12 }}>
                                <strong>Name:</strong> <em>{user.name}</em>
                              </p>

                              <p style={{ fontSize: 12 }}>
                                <strong>Email:</strong> <em>{user.email}</em>
                              </p>

                              <p style={{ fontSize: 12 }}>
                                <strong>Phone:</strong>{' '}
                                <em>{user.phone_number}</em>
                              </p>
                            </div>

                            <div>
                              <p style={{ fontSize: 11 }}>
                                <em>
                                  [If we are not correct, or you need to change
                                  the information then enter new email by
                                  clicking the button. Changing email will
                                  change the entire previous history of this
                                  email including previous booking.]
                                </em>
                              </p>
                            </div>

                            <div
                              style={{
                                marginTop: 10,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}>
                              <Button
                                size='small'
                                onClick={async () => {
                                  dispatch(resetAuth());
                                  dispatch(resetChecked());

                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 500);
                                }}
                                style={{
                                  color: Colors.primaryColorLight,
                                }}>
                                Change Info
                              </Button>
                            </div>
                          </React.Fragment>
                        }>
                        <ContactSupportOutlinedIcon
                          fontSize='small'
                          style={{ opacity: 0.6 }}
                          onClick={() => {
                            setOpenToolTip(true);
                          }}
                        />
                      </HtmlTooltip>
                    </div>
                  </ClickAwayListener>
                </div>
              </div>
            )}
            <div className='home_enquiry_section_form_content_way'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onClick={() => {
                      setChecked((prev) => !prev);
                    }}
                  />
                }
                label='One Way Trip'
              />
              <FormControlLabel
                control={<Checkbox checked={!checked} />}
                label='Round Trip'
                onClick={() => {
                  setChecked((prev) => !prev);
                }}
              />
            </div>
            <div className='home_enquiry_section_form_content_linp'>
              {pickupSNote && (
                <p style={{ color: 'red', margin: 0 }}>{pickupSNote}</p>
              )}
              <Autocomplete
                style={{
                  padding: '8.5px 10px',
                  borderColor: Colors.lightGrey,
                  outline: 'none',
                  boxShadow: 'none',
                  borderRadius: 5,
                  borderWidth: 1,
                  fontFamily: 'DM Sans',
                  fontSize: 16,
                }}
                options={{
                  types: ['geocode'],
                  componentRestrictions: { country: 'uk' },
                }}
                onChange={() => {
                  setPickupLocation({ location_name: '', cords: [] });
                }}
                onBlur={() => {
                  if (pickupLocation.cords.length === 0) {
                    setPickupSNote(
                      'Please provide the complete address then select it from the dropdown options to ensure you receive the best possible price.'
                    );
                  }
                }}
                placeholder='Pickup address'
                apiKey={URLConfig.mapsGeocodingAPIKey}
                onPlaceSelected={(place) => {
                  if (place.geometry?.location && place.formatted_address) {
                    const locationDetails = {
                      location_name: place.formatted_address,
                      cords: [
                        place.geometry.location.lng(),
                        place.geometry.location.lat(),
                      ],
                    };

                    setPickupLocation(locationDetails);
                    setPickupSNote('');
                  } else {
                    alert('Please select a valid  address');
                  }
                }}
              />

              {dropSNote && (
                <p style={{ color: 'red', margin: 0 }}>{dropSNote}</p>
              )}

              <Autocomplete
                style={{
                  padding: '8.5px 10px',
                  borderColor: Colors.lightGrey,
                  outline: 'none',
                  boxShadow: 'none',
                  borderRadius: 5,
                  borderWidth: 1,
                  fontFamily: 'DM Sans',
                  fontSize: 16,
                }}
                options={{
                  types: ['geocode'],
                  componentRestrictions: { country: 'uk' },
                }}
                onChange={() => {
                  setDropLocation({ location_name: '', cords: [] });
                }}
                onBlur={() => {
                  if (pickupLocation.cords.length === 0) {
                    setPickupSNote(
                      'Please provide the complete address then select it from the dropdown options to ensure you receive the best possible price.'
                    );
                  }
                }}
                placeholder='Destination address'
                apiKey={URLConfig.mapsGeocodingAPIKey}
                onPlaceSelected={(place) => {
                  if (place.geometry?.location && place.formatted_address) {
                    const locationDetails = {
                      location_name: place.formatted_address,
                      cords: [
                        place.geometry.location.lng(),
                        place.geometry.location.lat(),
                      ],
                    };

                    setDropLocation(locationDetails);
                    setDropSNote('');
                  } else {
                    alert('Please select a valid  address');
                  }
                }}
              />
            </div>
            <div className='home_enquiry_section_form_content_drdt'>
              <DateTimePicker
                label='Departure Date Time'
                format='LLL'
                onChange={(val: any) => {
                  setPickupDateTime(moment(val).format());
                }}
                minDateTime={moment(new Date())}
              />
              {!checked && (
                <DateTimePicker
                  label='Return Date Time'
                  format='LLL'
                  onChange={(val: any) => {
                    setReturnDateTime(moment(val).format());
                  }}
                  minDateTime={moment(pickupDateTime)}
                />
              )}
            </div>

            <div className='home_enquiry_section_form_content_pr'>
              <TextField
                value={passengerCount}
                onChange={(val) => {
                  setPassengerCount(val.target.value);
                }}
                id='passenger_count'
                label='Number of Passengers'
                variant='outlined'
                type='number'
              />
              <FormControl style={{}}>
                <MUISelect
                  value={travellingReason}
                  id='travelling-reason-select'
                  onChange={(event: any) => {
                    setTravellingReason(event.target.value);
                  }}>
                  <MenuItem value={'0'}>Travelling reason</MenuItem>
                  {travelling_reason_arr.map((tr) => {
                    return (
                      <MenuItem key={tr.value} value={tr.value}>
                        {tr.label}
                      </MenuItem>
                    );
                  })}
                </MUISelect>
              </FormControl>
            </div>

            <div className='home_enquiry_section_form_content_vehicle'>
              <FormControl style={{}}>
                <MUISelect
                  value={vehicleType}
                  id='vehilce-select'
                  onChange={(event: any) => {
                    setVehicleType(event.target.value);
                  }}>
                  <MenuItem value={'0'}>
                    Select vehicle capacity you need
                  </MenuItem>
                  {vahicle_types_arr.map((va) => {
                    return (
                      <MenuItem key={va.value} value={va.value}>
                        {va.label}
                      </MenuItem>
                    );
                  })}
                </MUISelect>
              </FormControl>
            </div>

            <div className='home_enquiry_section_form_content_vl'>
              <TextField
                value={vehicleCount}
                onChange={(val) => {
                  setVehicleCount(val.target.value);
                }}
                id='vehicle_count'
                label='Number of Vehicle'
                variant='outlined'
                type='number'
              />
              <FormControl style={{}}>
                <MUISelect
                  value={luggageType}
                  id='luggage-select'
                  onChange={(event: any) => {
                    setLuggageType(event.target.value);
                  }}>
                  <MenuItem value={'0'}>Luggage Type</MenuItem>
                  {luggage_type_arr.map((lr) => {
                    return (
                      <MenuItem key={lr.value} value={lr.value}>
                        {lr.label}
                      </MenuItem>
                    );
                  })}
                </MUISelect>
              </FormControl>
            </div>

            <div className='home_enquiry_section_form_content_message'>
              <textarea
                value={messageOptional}
                onChange={(val) => {
                  setMessageOptional(val.target.value);
                }}
                name='message_optional'
                id='message_optional'
                cols={36}
                rows={7}
                style={{}}
                placeholder={`Please use this section to add any additional requirements you may have. For Example, we need additional drop offs on the return journey.`}></textarea>
            </div>
            <div className='home_enquiry_section_form_content_action'>
              <CustomButton
                title={
                  isSubmitting ? `Please wait...` : 'Send Enquiry to Companies'
                }
                isLoading={isSubmitting}
                isDisabled={
                  !(
                    pickupLocation.cords.length > 0 &&
                    dropLocation.cords.length > 0 &&
                    !!pickupDateTime &&
                    !!passengerCount &&
                    !!vehicleCount &&
                    luggageType.length > 2 &&
                    travellingReason.length > 2 &&
                    vehicleType.length > 2
                  )
                }
                onClick={async () => {
                  setIsSubmitting(true);

                  const ref_token = search.split('=')[1];
                  const referral_data: AddEmptyReferralInput = {
                    status: 'accepting_offer',
                    referred_to: user._id,
                    progress: { step: 2 },
                    trip_info: {
                      tripType: checked ? 'one_way_trip' : 'round_trip',
                      pickup_location: pickupLocation,
                      destination: dropLocation,
                      pickup_datetime: pickupDateTime,
                      return_datetime: returnDateTime || undefined,
                      passenger_count: +passengerCount,
                      note: messageOptional || '',
                      travelling_reason: travellingReason,
                      vehicle_type: vehicleType,
                      luggage_type: luggageType,
                      vehicle_count: +vehicleCount,
                    },
                  };

                  if (ref_token) {
                    try {
                      const res = await isReferralTokenExists({
                        referral_token: ref_token,
                      });
                      const referral = res.isReferralTokenExists;

                      if (referral) {
                        if (
                          referral.status === 'filling_customer_information'
                        ) {
                          await updateReferral({
                            _id: referral._id,
                            ...referral_data,
                          });
                          setTimeout(() => {
                            navigate('/enquiry-sent');
                          }, 100);
                        } else {
                          alert(
                            'You already submitted enquiry to this Referral Token, Try filling up Information again with new referral link.'
                          );
                          setTimeout(() => {
                            navigate('/');
                          }, 100);
                        }
                      } else {
                        throw new Error(JSON.stringify(res.error));
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  } else {
                    try {
                      const res = await addEmptyReferral(referral_data);
                      const referral = res.addEmptyReferral;
                      if (referral) {
                        setTimeout(() => {
                          navigate('/enquiry-sent');
                        }, 100);
                      } else {
                        throw new Error(JSON.stringify(res.error));
                      }
                    } catch (err) {
                      console.log(err);
                    }
                  }

                  setIsSubmitting(false);
                }}
              />
            </div>
          </div>
        </div>
        <div className='home_enquiry_section_ad'>
          <h1>Your trip just got alot easier</h1>

          <p>
            You've been reffered by a trusted operator. Once you've submitted
            your trip details, you'll start to receive Prices & Offers from all
            the available companies in your area.
          </p>

          <img
            className='bus_img'
            style={{
              cursor: 'pointer',
            }}
            src='/images/bus_big.png'
            alt='Bus'
          />
        </div>
      </div>

      {reviews && reviews.length > 0 && (
        <div className='home_testimonial_section'>
          <h2>What Our Customers Say</h2>
          {/* WILL CHAGE TO CUSTOMER REVIEW TO COMPANY */}
          <div className='home_testimonial_section_items'>
            {reviews?.map((rv: any) => {
              return (
                <ReviewListItem
                  _id={rv._id}
                  key={rv._id}
                  createdAt={rv.createdAt}
                  rating={rv.rating}
                  message={rv.message}
                  contact_name={rv.review_to.contact_name}
                  referral_code={rv.review_to.referral_code}
                  photo={rv.review_to.photo}
                  isPhotoVisible={rv.review_to.isPhotoVisible}
                  review_by={{
                    name: rv.review_by.name,
                  }}
                />
              );
            })}
          </div>
          <div className='home_testimonial_section_action'>
            <input
              type='submit'
              value='View More Reviews'
              onClick={() => {
                navigate('/reviews');
              }}
            />
          </div>
        </div>
      )}

      <CustomerInfoDialog isOpen={isModalVisible} />
    </div>
  );
};

export default HomePage;
