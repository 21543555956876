import { useLocation } from 'react-router-dom';
import Footer from './Footer';
import './Layout.scss';
import MainNavigation from './MainNavigation';
import BusinessPageLayout from './BusinessPageLayout';

const Layout: React.FC<{ children: React.ReactNode }> = (props) => {
  const { pathname } = useLocation();

  return (
    <div className='wrapper'>
      {(pathname.includes('/privacy-app') ||
        pathname.includes('/terms-app') ||
        pathname.includes('/about-app') ||
        pathname.includes('/payment-app')) && (
        <div className='main-app'>{props.children}</div>
      )}
      {!pathname.includes('/privacy-app') &&
        !pathname.includes('/terms-app') &&
        !pathname.includes('/about-app') &&
        !pathname.includes('/payment-app') && (
          <>
            {pathname.includes('/businesspage') && (
              <BusinessPageLayout>{props.children}</BusinessPageLayout>
            )}
            {!pathname.includes('/businesspage') && (
              <>
                <MainNavigation />
                <main className='main'>
                  <div className='main_content'>{props.children}</div>
                </main>
                <Footer />
              </>
            )}
          </>
        )}
    </div>
  );
};
export default Layout;
