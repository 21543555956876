import * as React from 'react';
import Rating from '@mui/material/Rating';
import { getNameAvatar, getRatingLabel } from '../../utils/functions';
import './ReviewListItem.scss';
import { Avatar } from '@mui/material';
import Colors from '../../constants/Colors';
import moment from 'moment';
import { URLConfig, gBaseUrl } from '../../utils/config';

const ReviewListItem: React.FC<{
  _id: string;
  createdAt: Date;
  rating?: number;
  message?: string;
  contact_name: string;
  photo: string;
  isPhotoVisible: boolean;
  referral_code: string;
  review_by: {
    name: string;
  };
}> = ({
  _id,
  createdAt,
  rating,
  message,
  contact_name,
  referral_code,
  isPhotoVisible,
  photo,
  review_by: { name },
}) => {
  return (
    <div className='reviews_cards_item'>
      <div className='review_item_details'>
        <div className='review_by'>
          <p>
            <strong>Review By - </strong> {name}
          </p>
        </div>
        <div className='title_logo'>
          <div className='logo_comp'>
            {isPhotoVisible ? (
              <Avatar
                src={`${gBaseUrl}/photos/avatars/${photo}`}
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                }}
              />
            ) : (
              <Avatar
                sx={{
                  bgcolor: Colors.pclXLight,
                  marginRight: 1,
                  color: Colors.primaryColorLight,
                  boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                }}>
                {getNameAvatar(contact_name)}
              </Avatar>
            )}
            <div className='logo_comp_star'>
              <p className='business_name'>{contact_name}</p>
              <p className='business_id'>#{referral_code}</p>
            </div>
          </div>
        </div>
        <div className='content_wrapper'>
          <p className='content_text'>{message}</p>
        </div>
      </div>

      <div className='rating_wrapper'>
        <div className='rating_star'>
          <p style={{ marginRight: 5 }}>{rating?.toFixed(1)}</p>
          <Rating
            name='simple-controlled'
            value={rating}
            size='small'
            onChange={(event, newValue) => {
              //
            }}
          />

          <div className='rating_status'>
            <p>{getRatingLabel(rating || 5)}</p>
          </div>
        </div>

        <div className='rating_date'>
          <p>{moment(createdAt).format('MMM DD, YYYY')}</p>
        </div>
      </div>
    </div>
  );
};

export default ReviewListItem;
