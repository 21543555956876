import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  AddBpenquiryInput,
  Bpenquiry,
} from '../../../../types/schema/bpenquiry.types';

const GQL_MUT = gql`
  mutation AddBpenquiry($input: AddBpenquiryInput!) {
    addBpenquiry(input: $input) {
      _id
      bpenquiry_token
      isActive
      company_ref {
        _id
        domain_name
      }
    }
  }
`;

// Define the types for the mutation response and input
interface AddBpenquiryMutationData {
  addBpenquiry: Bpenquiry;
}

interface AddBpenquiryInputType {
  input: AddBpenquiryInput;
}

export const useAddBpenquiry = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [addBpenquiryGql, { data, error, loading }] = useMutation<
    AddBpenquiryMutationData,
    AddBpenquiryInputType
  >(GQL_MUT);

  const addBpenquiry = async (input: AddBpenquiryInput) => {
    const { data, errors } = await addBpenquiryGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      addBpenquiry: data?.addBpenquiry,
      error: errors ? errors[0].message : null,
    };
  };

  return { addBpenquiry, data, error, loading };
};
