import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { TextInput } from '../../../../types/schema/utils.types';
import { User } from '../../../../types/schema/user.types';

const GQL_MUT = gql`
  mutation IsDomainExists($input: TextInput!) {
    isDomainExists(input: $input) {
      _id
      domain_name
      photo
      isPhotoVisible
    }
  }
`;

// Define the types for the mutation response and input
interface IsDomainExistsMutationData {
  isDomainExists: User;
}

interface IsDomainExistsInputType {
  input: TextInput;
}

export const useIsDomainExists = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [isDomainExistsGql, { data, error, loading }] = useMutation<
    IsDomainExistsMutationData,
    IsDomainExistsInputType
  >(GQL_MUT);

  const isDomainExists = async (input: TextInput) => {
    const { data, errors } = await isDomainExistsGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      isDomainExists: data?.isDomainExists,
      error: errors ? errors[0].message : null,
    };
  };

  return { isDomainExists, data, error, loading };
};
