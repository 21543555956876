import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import {
  Proposal,
  UpdateProposalInput,
} from '../../../../types/schema/proposal.types';

const GQL_MUT = gql`
  mutation UpdateProposalOnBpenquiry($input: UpdateProposalInput!) {
    updateProposalOnBpenquiry(input: $input) {
      _id
    }
  }
`;

// Define the types for the mutation response and input
interface UpdateProposalOnBpenquiryMutationData {
  updateProposalOnBpenquiry: Proposal;
}

interface UpdateProposalOnBpenquiryInputType {
  input: UpdateProposalInput;
}

export const useUpdateProposalOnBpenquiry = () => {
  const { jwt } = useSelector((state: RootState) => state.auth);

  const [updateProposalOnBpenquiryGql, { data, error, loading }] = useMutation<
    UpdateProposalOnBpenquiryMutationData,
    UpdateProposalOnBpenquiryInputType
  >(GQL_MUT);

  const updateProposalOnBpenquiry = async (input: UpdateProposalInput) => {
    const { data, errors } = await updateProposalOnBpenquiryGql({
      variables: {
        input: input,
      },
      context: {
        headers: {
          cookie: `jwt=${jwt}`,
          authorization: jwt ? `Bearer ${jwt}` : '',
        },
      },
    });

    return {
      updateProposalOnBpenquiry: data?.updateProposalOnBpenquiry,
      error: errors ? errors[0].message : null,
    };
  };

  return { updateProposalOnBpenquiry, data, error, loading };
};
