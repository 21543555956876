/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { TextField } from '@mui/material';
import { useRef, useState } from 'react';
import { resetAuth, authenticate } from '../../store/reducer/auth';
import CustomButton from '../UI/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useIsCustomerEmailExists } from '../../hooks/gql/mutation/action/useIsCustomerEmailExists';
import { useAddCustomer } from '../../hooks/gql/mutation/create/useAddCustomer';

const CustomerInfoForm: React.FC<{
  onSetBookingForm: (isSet: boolean) => void;
}> = ({ onSetBookingForm }) => {
  const dispatch = useDispatch();
  const { isCustomerEmailExists } = useIsCustomerEmailExists();
  const { addCustomer } = useAddCustomer();

  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.auth
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Customer Details
  const customerEmailRef = useRef<HTMLInputElement>(null);
  const [customerEmail, setCustomerEmail] = useState(user?.email);
  const customerNameRef = useRef<HTMLInputElement>(null);
  const [customerName, setCustomerName] = useState(user?.name);
  const customerPhoneRef = useRef<HTMLInputElement>(null);
  const [customerPhone, setCustomerPhone] = useState(user?.phone_number);

  return (
    <>
      <div className='bp_booking_form_section_form_content_cusemail'>
        <TextField
          inputRef={customerEmailRef}
          defaultValue={customerEmail}
          onChange={(e) => {
            setCustomerEmail(e.target.value);
          }}
          id='customer_email'
          variant='outlined'
          required
          label='Your Email'
          type='email'
        />
      </div>

      <div className='bp_booking_form_section_form_content_emailaction'>
        <CustomButton
          title={isSubmitting ? `Please wait...` : 'Reset'}
          isLoading={isSubmitting}
          isDisabled={!isAuthenticated}
          onClick={async () => {
            dispatch(resetAuth());
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }}
        />

        {!isAuthenticated && (
          <CustomButton
            title={isSubmitting ? `Please wait...` : 'Login'}
            isLoading={isSubmitting}
            isDisabled={!customerEmail?.includes('@')}
            onClick={async () => {
              setIsSubmitting(true);
              if (customerEmail) {
                try {
                  const res = await isCustomerEmailExists({
                    email: customerEmail.toLowerCase(),
                  });
                  const customer = res.isCustomerEmailExists;

                  if (customer) {
                    dispatch(
                      authenticate({
                        isAuthenticated: true,
                        user: customer,
                      })
                    );

                    alert("That's Enough! We've got you. Thanks");
                    setIsSubmitting(false);
                    onSetBookingForm(true);

                    setTimeout(() => {
                      window.location.reload();
                    }, 500);
                  } else {
                    alert(
                      'Oops! We could not find your information please fill up other information and submit us to record your data for future login.'
                    );

                    setTimeout(() => {
                      customerNameRef.current?.focus();
                    }, 500);
                  }
                } catch (err) {
                  console.log(err);
                  alert(
                    'Oops! We could not find your information please fill up other information and submit us to record your data for future login.'
                  );
                  setTimeout(() => {
                    customerNameRef.current?.focus();
                  }, 500);
                }
              }

              setIsSubmitting(false);
            }}
          />
        )}
      </div>

      <div className='bp_booking_form_section_form_content_cusname'>
        <TextField
          inputRef={customerNameRef}
          defaultValue={customerName}
          onChange={(e) => {
            setCustomerName(e.target.value);
          }}
          id='customer_name'
          label='Your Name'
          required
          variant='outlined'
          type='text'
        />
      </div>

      <div className='bp_booking_form_section_form_content_cusphone'>
        <TextField
          inputRef={customerPhoneRef}
          defaultValue={customerPhone}
          onChange={(e) => {
            setCustomerPhone(e.target.value);
          }}
          required
          id='customer_phone'
          label='Your Phone'
          variant='outlined'
          type='tel'
        />
      </div>

      <div className='bp_booking_form_section_form_content_action'>
        <CustomButton
          title={isSubmitting ? `Please wait...` : 'Submit Customer Info'}
          isLoading={isSubmitting}
          isDisabled={
            !(
              customerEmail &&
              customerEmail.includes('@') &&
              customerPhone &&
              customerName &&
              !isAuthenticated
            )
          }
          onClick={async () => {
            setIsSubmitting(true);

            if (customerEmail) {
              const customer_datails = {
                name: customerName || customerNameRef.current?.value!,
                email:
                  customerEmail ||
                  customerEmailRef.current?.value.toLowerCase()!,
                phone_number: customerPhone || customerPhoneRef.current?.value!,
              };

              try {
                const res = await isCustomerEmailExists({
                  email: customerEmail.toLowerCase(),
                });
                const customer = res.isCustomerEmailExists;

                if (customer) {
                  dispatch(
                    authenticate({
                      isAuthenticated: true,
                      user: customer,
                    })
                  );

                  alert("That's Enough! We've got you. Thanks");
                  onSetBookingForm(true);
                  setIsSubmitting(false);

                  setTimeout(() => {
                    window.location.reload();
                  }, 500);

                  return;
                }

                const resNew = await addCustomer(customer_datails);

                const customerNew = resNew.addCustomer;

                if (customerNew) {
                  dispatch(
                    authenticate({
                      isAuthenticated: true,
                      user: customerNew,
                    })
                  );

                  alert("That's Enough! We've got you. Thanks");
                  setIsSubmitting(false);
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                } else {
                  throw new Error(JSON.stringify(resNew.error));
                }
              } catch (err) {
                console.log(err);
              }
            }
            setIsSubmitting(false);
          }}
        />
      </div>
    </>
  );
};

export default CustomerInfoForm;
