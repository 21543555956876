import React, { useEffect, useRef, useState } from 'react';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import { gql } from '@apollo/client';
import './BusinessPageAllEnquiriesDetailsPage.scss';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import OfferCardListItem from '../../components/ListItem/OfferCardListItem';
import IconButton from '@mui/material/IconButton';
import SyncIcon from '@mui/icons-material/Sync';
import SendIcon from '@mui/icons-material/Send';
import Colors from '../../constants/Colors';
import { gBaseUrl } from '../../utils/config';
import {
  formatString,
  getNameAvatar,
  getRatingLabel,
} from '../../utils/functions';
import { Avatar, CircularProgress, Rating } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';
import { useGetLatestReviewByCompanyId } from '../../hooks/gql/query/getByValue/useGetLatestReviewByCompanyId';
import { useGetProposalDetailsById } from '../../hooks/gql/query/getById/useGetProposalDetailsById';
import { useGetSetting } from '../../hooks/gql/query/useGetSetting';
import { Proposal } from '../../types/schema/proposal.types';
import { useGetBpenquiryDetailsById } from '../../hooks/gql/query/getById/useGetBpenquiryDetailsById';
import { useGetAllProposalsByBpenquiryId } from '../../hooks/gql/query/getAll/useGetAllProposalsByBpenquiryId';
import { useUpdateProposalOnBpenquiry } from '../../hooks/gql/mutation/update/useUpdateProposalOnBpenquiry';

const GET_SETTINGS = gql`
  query GetSetting {
    getSetting {
      _id
      name
      upfrontPercentage
      maxNegBalance
    }
  }
`;

const GET_BPENQ_DETAILS = gql`
  query GetBpenquiryDetailsById($input: MongoIdInput!) {
    getBpenquiryDetailsById(input: $input) {
      _id
      status
      customer_ref {
        _id
      }
      proposals {
        _id
        proposed_by {
          _id
        }
      }
      trip_info {
        pickup_location {
          cords
          location_name
        }
        destination {
          cords
          location_name
        }
        tripType
        pickup_datetime
        return_datetime
        passenger_count
        travelling_reason
      }
    }
  }
`;

const GET_ALL_PROPOSALS_BY_BPENQ_ID = gql`
  query GetAllProposalsByBpenquiryId($input: MongoIdInput!) {
    getAllProposalsByBpenquiryId(input: $input) {
      _id
      proposed_by {
        _id
        referral_code
        contact_name
        photo
        isPhotoVisible
      }
    }
  }
`;

const GET_PROPOSAL_DETAILS_BY_ID = gql`
  query GetProposalDetailsById($input: MongoIdInput!) {
    getProposalDetailsById(input: $input) {
      _id
      proposal_amount
      agreed_amount
      selected_vehicle
      proposed_bpenquiry_ref {
        _id
        company_ref {
          _id
        }
      }
      proposed_by {
        _id
        contact_name
        company_name
        business_address {
          location_name
        }
        paymentMethods
        photo
        isPhotoVisible
        phone
      }
      conversations {
        _id
        sender
        content
        sentAt
      }
    }
  }
`;

const GET_LATEST_REV_BY_COMPID = gql`
  query GetLatestReviewByCompanyId($input: MongoIdInput!) {
    getLatestReviewByCompanyId(input: $input) {
      _id
      review_to {
        _id
        contact_name
        referral_code
        photo
        isPhotoVisible
      }
      review_by {
        _id
        name
      }
      message
      rating
    }
  }
`;

const BusinessPageAllEnquiriesDetailsPage: React.FC<{}> = () => {
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const cuRef = useRef<HTMLDivElement>(null);
  const { slug, bpenq } = useParams();
  const [zoomImage, setZoomImage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cuMessage, setCuMessage] = useState('');
  const [currentProposal, setCurrentProposal] = useState(
    '64f8267c7ed1f6aa0166bde1'
  );
  const [selectedCompany, setSelectedCompany] = useState(
    '64f8267c7ed1f6aa0166bde1'
  );

  const {
    data: settings,
    error: settingsError,
    loading: settingsLoading,
    refetch: settingsRefectch,
  } = useGetSetting(GET_SETTINGS);

  const {
    data: bpenquiry,
    error: bpenquiryError,
    loading: bpenquiryLoading,
    refetch: bpenquiryRefectch,
  } = useGetBpenquiryDetailsById(GET_BPENQ_DETAILS, {
    _id: bpenq || '64f8267c7ed1f6aa0166bde1',
  });

  const {
    data: latestReview,
    error: latestReviewError,
    loading: latestReviewLoading,
    refetch: latestReviewRefectch,
  } = useGetLatestReviewByCompanyId(GET_LATEST_REV_BY_COMPID, {
    _id: selectedCompany || '64f8267c7ed1f6aa0166bde1',
  });

  const {
    data: proposals,
    error: proposalsError,
    loading: proposalsLoading,
    refetch: proposalsRefectch,
  } = useGetAllProposalsByBpenquiryId(GET_ALL_PROPOSALS_BY_BPENQ_ID, {
    _id: bpenq || '64f8267c7ed1f6aa0166bde1',
  });

  const {
    data: proposal,
    error: proposalError,
    loading: proposalLoading,
    refetch: proposalRefectch,
  } = useGetProposalDetailsById(GET_PROPOSAL_DETAILS_BY_ID, {
    _id: currentProposal,
  });

  const { updateProposalOnBpenquiry } = useUpdateProposalOnBpenquiry();

  const proposed_by = proposal?.proposed_by;
  const proposed_bpenquiry = proposal?.proposed_bpenquiry_ref;
  const currentConversations = proposal?.conversations || [];
  const sortedProposals: Proposal[] = proposals || [];
  const isAccepted = bpenquiry?.status === 'offer_accepted';
  const isPermitted =
    !isAccepted && moment(bpenquiry?.trip_info?.pickup_datetime).isAfter();
  const isApprovedProposal =
    isAccepted &&
    proposed_by?._id.toString() ===
      proposed_bpenquiry?.company_ref?._id.toString();

  useEffect(() => {
    if (proposals && proposals.length > 0) {
      setCurrentProposal(proposals[0]._id);
      setSelectedCompany(proposals[0].proposed_by._id);
      proposalRefectch();
      latestReviewRefectch();
    }
  }, [proposalsLoading]);

  useEffect(() => {
    cuRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [currentConversations.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      proposalRefectch();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='bpenquiry_details_wrapper'>
      {!isAuthenticated || user?._id !== bpenquiry?.customer_ref._id ? (
        <>
          <div className='no_offer'>
            <p style={{ textAlign: 'center' }}>
              To check your update, choose the 'Profile' option from the menu in
              the top left corner. Then provide your email to login as a
              customer first.
              <strong
                onClick={() => navigate('/')}
                style={{
                  cursor: 'pointer',
                  color: Colors.primaryColorLight,
                }}>
                Profile Page
              </strong>{' '}
            </p>
          </div>
        </>
      ) : (
        <>
          <div className='bpenquiry_header' style={{}}>
            <div className='enquiry_header_left'>
              <div className='enquiry_header_left_pickup'>
                <h4>Pickup Location</h4>
                <p>
                  {bpenquiry?.trip_info?.pickup_location?.location_name || '--'}
                </p>
              </div>
              <div className='enquiry_header_left_car'>
                <div className='car_loading'>
                  <AirportShuttleOutlinedIcon
                    fontSize='large'
                    style={{ color: '#fff', marginBottom: -15 }}
                  />
                </div>
                <div className='car_dots'>
                  <div className='left_dot'></div>
                  <div className='dashed_line'></div>
                  <div className='right_dot'></div>
                </div>
              </div>
              <div className='enquiry_header_left_drop'>
                <h4>Destination</h4>
                <p>
                  {bpenquiry?.trip_info?.destination?.location_name || '--'}
                </p>
              </div>

              <div className='enquiry_header_left_rft'>
                <h4>Reason For Travel</h4>
                <p>{formatString(bpenquiry?.trip_info?.travelling_reason)}</p>
              </div>
            </div>
            <div className='enquiry_header_right'>
              <div className='enquiry_header_right_pickupdate'>
                <h4>Pickup Date</h4>
                <p>
                  {bpenquiry?.trip_info?.pickup_datetime
                    ? moment(bpenquiry?.trip_info?.pickup_datetime).format(
                        'lll'
                      )
                    : '--'}
                </p>
              </div>
              <div className='enquiry_header_right_dropdate'>
                <h4>Return Date</h4>
                <p>
                  {bpenquiry?.trip_info?.return_datetime
                    ? moment(bpenquiry?.trip_info?.return_datetime).format(
                        'lll'
                      )
                    : '--'}
                </p>
              </div>
              <div className='enquiry_header_right_pass'>
                <h4>Passengers</h4>
                <p>{bpenquiry?.trip_info?.passenger_count}</p>
              </div>
            </div>
          </div>

          {bpenquiry &&
            bpenquiry.proposals &&
            bpenquiry.proposals.length > 0 && (
              <div className='bpenquiry_details'>
                <div className='bpenquiry_details_left'>
                  <div className='offers_cards'>
                    {sortedProposals?.map((p, i: number) => {
                      return (
                        <OfferCardListItem
                          i={i + 1}
                          id={p._id}
                          key={p._id}
                          isRecommended={true}
                          activeProposalId={currentProposal}
                          contact_name={p.proposed_by.contact_name}
                          photo={p.proposed_by.photo}
                          isPhotoVisible={p.proposed_by.isPhotoVisible}
                          referral_code={p.proposed_by.referral_code}
                          onItemClick={(id) => {
                            setCurrentProposal(id);
                            setSelectedCompany(p.proposed_by._id);
                            latestReviewRefectch();
                            proposalRefectch();
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className='bpenquiry_details_right' style={{}}>
                  <p style={{ textAlign: 'center', marginTop: 10 }}>
                    You are talking to {proposed_by?.contact_name}
                  </p>

                  <div className='bpenquiry_details_right_chat'>
                    <div className='bpenquiry_details_right_chat--c'>
                      {currentConversations?.map((cv, i: number) => {
                        if (cv.sender === 'company') {
                          return (
                            <div key={i}>
                              {i === 0 && latestReview && (
                                <motion.div
                                  key={i}
                                  initial={{ y: 10, opacity: 0 }}
                                  animate={{ y: 0, opacity: 1 }}
                                  transition={{ duration: 0.5 }}
                                  className='bpenquiry_details_right_chat_left'>
                                  <div className='avater_wrapper'>
                                    {proposed_by?.isPhotoVisible ? (
                                      <Avatar
                                        src={`${gBaseUrl}/photos/avatars/${proposed_by?.photo}`}
                                        sx={{
                                          bgcolor: Colors.pclXLight,
                                          width: 30,
                                          height: 30,
                                          marginRight: 1,
                                          color: Colors.primaryColorLight,
                                          boxShadow:
                                            '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                        }}
                                      />
                                    ) : (
                                      <Avatar
                                        sx={{
                                          width: 30,
                                          height: 30,
                                          bgcolor: Colors.pclXLight,
                                          marginRight: 1,
                                          color: Colors.primaryColorLight,
                                          boxShadow:
                                            '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                        }}>
                                        {getNameAvatar(
                                          proposed_by?.contact_name || 'User'
                                        )}
                                      </Avatar>
                                    )}
                                  </div>
                                  <div className='message_wrapper'>
                                    <div className='review_company_det'>
                                      {latestReview?.review_to
                                        ?.isPhotoVisible ? (
                                        <Avatar
                                          src={`${gBaseUrl}/photos/avatars/${latestReview?.review_to?.photo}`}
                                          sx={{
                                            bgcolor: Colors.pclXLight,
                                            marginRight: 1,
                                            color: Colors.primaryColorLight,
                                            boxShadow:
                                              '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                          }}
                                        />
                                      ) : (
                                        <Avatar
                                          sx={{
                                            width: 26,
                                            height: 26,
                                            padding: 1,
                                            bgcolor: Colors.pclXLight,
                                            marginRight: 1,
                                            color: Colors.primaryColorLight,
                                            boxShadow:
                                              '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                          }}>
                                          {getNameAvatar(
                                            latestReview?.review_to
                                              ?.contact_name
                                          )}
                                        </Avatar>
                                      )}

                                      <div>
                                        <p
                                          style={{
                                            fontWeight: 'bold',
                                            marginBottom: 5,
                                          }}>
                                          {
                                            latestReview?.review_to
                                              ?.contact_name
                                          }
                                        </p>
                                        <p>
                                          #
                                          {
                                            latestReview?.review_to
                                              ?.referral_code
                                          }
                                        </p>
                                      </div>
                                    </div>
                                    <div className='review_company_rev'>
                                      <p
                                        style={{
                                          fontWeight: 'bold',
                                          marginBottom: 5,
                                        }}>
                                        Recent Review -{' '}
                                        {latestReview?.review_by?.name}{' '}
                                      </p>
                                      <p>{latestReview?.message}</p>
                                    </div>
                                    <div className='review_company_stars'>
                                      <div className='rating_star'>
                                        <p style={{ marginRight: 10 }}>
                                          {latestReview.rating?.toFixed(1)}
                                        </p>
                                        <Rating
                                          name='simple-controlled'
                                          value={latestReview?.rating}
                                          size='small'
                                          disabled={true}
                                          onChange={(event, newValue) => {
                                            //
                                          }}
                                        />

                                        <div className='rating_status'>
                                          <p>
                                            {getRatingLabel(
                                              latestReview?.rating || 5
                                            )}
                                          </p>
                                        </div>
                                      </div>

                                      <div
                                        className='rating_action'
                                        onClick={() => {
                                          navigate(
                                            `/reviews?cID=${selectedCompany}`
                                          );
                                        }}>
                                        <p>View All</p>
                                        <LaunchIcon
                                          fontSize='small'
                                          color='primary'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </motion.div>
                              )}

                              <motion.div
                                initial={{ y: 10, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 0.5 }}
                                className='bpenquiry_details_right_chat_left'>
                                <div className='avater_wrapper'>
                                  {proposed_by?.isPhotoVisible ? (
                                    <Avatar
                                      src={`${gBaseUrl}/photos/avatars/${proposed_by?.photo}`}
                                      sx={{
                                        bgcolor: Colors.pclXLight,
                                        width: 30,
                                        height: 30,
                                        marginRight: 1,
                                        color: Colors.primaryColorLight,
                                        boxShadow:
                                          '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                      }}
                                    />
                                  ) : (
                                    <Avatar
                                      sx={{
                                        width: 30,
                                        height: 30,
                                        bgcolor: Colors.pclXLight,
                                        marginRight: 1,
                                        color: Colors.primaryColorLight,
                                        boxShadow:
                                          '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                      }}>
                                      {getNameAvatar(
                                        proposed_by?.contact_name || 'User'
                                      )}
                                    </Avatar>
                                  )}
                                </div>
                                <div className='message_wrapper'>
                                  <pre>{cv.content}</pre>
                                  {i === 0 && (
                                    <div>
                                      <br />
                                      <hr />
                                      <p style={{ marginTop: 10 }}>
                                        Payment Methods -
                                      </p>

                                      <div className='p_method_wrapper'>
                                        {proposed_by?.paymentMethods?.map(
                                          (pm: string) => {
                                            return (
                                              <p key={pm} className='p_method'>
                                                {formatString(pm)}
                                              </p>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {i === 0 && (
                                    <div
                                      className='company_info'
                                      style={{ marginTop: 10 }}>
                                      <br />
                                      <hr />
                                      <p style={{ marginTop: 10 }}>
                                        Comapny Name:{' '}
                                        <strong>
                                          {proposed_by?.company_name}
                                        </strong>
                                      </p>
                                      <p>
                                        Contact Name:{' '}
                                        <strong>
                                          {proposed_by?.contact_name}
                                        </strong>
                                      </p>
                                      <p>
                                        Phone Number:{' '}
                                        <strong>{proposed_by?.phone}</strong>
                                      </p>
                                      <p>
                                        Address:{' '}
                                        <strong>
                                          {
                                            proposed_by?.business_address
                                              .location_name
                                          }
                                        </strong>
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </motion.div>

                              {i === 0 && proposal?.selected_vehicle && (
                                <motion.div
                                  initial={{ y: 10, opacity: 0 }}
                                  animate={{ y: 0, opacity: 1 }}
                                  transition={{ duration: 0.5 }}
                                  className='bpenquiry_details_right_chat_left'>
                                  <div className='avater_wrapper'>
                                    {proposed_by?.isPhotoVisible ? (
                                      <Avatar
                                        src={`${gBaseUrl}/photos/avatars/${proposed_by?.photo}`}
                                        sx={{
                                          bgcolor: Colors.pclXLight,
                                          width: 30,
                                          height: 30,
                                          marginRight: 1,
                                          color: Colors.primaryColorLight,
                                          boxShadow:
                                            '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                        }}
                                      />
                                    ) : (
                                      <Avatar
                                        sx={{
                                          width: 30,
                                          height: 30,
                                          bgcolor: Colors.pclXLight,
                                          marginRight: 1,
                                          color: Colors.primaryColorLight,
                                          boxShadow:
                                            '0px 10px 15px -3px rgba(0, 0, 0, 0.1)',
                                        }}>
                                        {getNameAvatar(
                                          proposed_by?.contact_name || 'User'
                                        )}
                                      </Avatar>
                                    )}
                                  </div>
                                  <div className='message_wrapper'>
                                    <div className='vehicle_content'>
                                      <p>
                                        This is the vehicle image I am going to
                                        use for this trip.
                                      </p>

                                      <div className='vehicle_image'>
                                        <img
                                          style={{ borderRadius: 5 }}
                                          src={`${gBaseUrl}/photos/vehiclePictures/${proposal?.selected_vehicle}`}
                                        />

                                        <div
                                          className='zoom_image'
                                          onClick={() => {
                                            setZoomImage(true);
                                          }}>
                                          <ZoomOutMapIcon
                                            fontSize='large'
                                            color='info'
                                          />
                                        </div>

                                        <Modal
                                          open={zoomImage}
                                          onClose={() => {
                                            setZoomImage(false);
                                          }}
                                          closeAfterTransition
                                          slots={{ backdrop: Backdrop }}
                                          slotProps={{
                                            backdrop: {
                                              timeout: 500,
                                            },
                                          }}>
                                          <Fade in={zoomImage}>
                                            <Box
                                              sx={{
                                                position: 'absolute' as const,
                                                top: '50%',
                                                left: '50%',
                                                transform:
                                                  'translate(-50%, -50%)',
                                                width: '80%',
                                                bgcolor: Colors.light,
                                                boxShadow: 24,
                                                borderRadius: 2,
                                                p: 0.5,
                                              }}>
                                              <div
                                                className='zoomed_image_modal'
                                                style={{
                                                  position: 'relative',
                                                }}>
                                                <div
                                                  className='zoomed_image_action'
                                                  style={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: 0,
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={async () => {
                                                    setZoomImage(false);
                                                  }}>
                                                  <CloseIcon
                                                    fontSize='large'
                                                    color='info'
                                                  />
                                                </div>
                                                <img
                                                  style={{
                                                    width: '100%',
                                                    height: '80vh',
                                                    objectFit: 'cover',
                                                  }}
                                                  src={`${gBaseUrl}/photos/vehiclePictures/${proposal?.selected_vehicle}`}
                                                />
                                              </div>
                                            </Box>
                                          </Fade>
                                        </Modal>
                                      </div>
                                    </div>
                                  </div>
                                </motion.div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <motion.div
                              key={i}
                              initial={{ y: 10, opacity: 0 }}
                              animate={{ y: 0, opacity: 1 }}
                              transition={{ duration: 0.5 }}
                              className='bpenquiry_details_right_chat_right'>
                              <p>{cv.content}</p>
                            </motion.div>
                          );
                        }
                      })}

                      <div style={{ marginBottom: 100 }} />
                      <div ref={cuRef} />

                      <div className='alert_text'>
                        <p>
                          Ensure to discuss all terms before the operator
                          confirms your booking
                        </p>
                      </div>
                    </div>
                    <div className='bpenquiry_details_right_chat--i'>
                      <div className='bpenquiry_details_right_chat--i_inp'>
                        <textarea
                          name=''
                          id=''
                          placeholder='Type new message...'
                          value={cuMessage}
                          onChange={(e) => {
                            const text = e.target.value;
                            setCuMessage(text);
                          }}
                          cols={20}
                          rows={2}></textarea>
                      </div>
                      <div className='bpenquiry_details_right_chat--i_sub'>
                        <IconButton
                          onClick={async () => {
                            setIsSubmitting(true);
                            try {
                              // Update Proposal Conversations
                              await updateProposalOnBpenquiry({
                                _id: currentProposal,
                                conversations: {
                                  sender: 'customer',
                                  content: cuMessage,
                                },
                              });

                              // Finally Refetch Proposal
                              proposalRefectch();

                              setCuMessage('');

                              setIsSubmitting(false);
                            } catch (err) {
                              setIsSubmitting(false);
                              console.log(err);
                            }
                          }}
                          disabled={!(cuMessage.trim().length >= 2)}
                          style={{ color: '#fff', backgroundColor: '#002661' }}>
                          {isSubmitting ? (
                            <CircularProgress
                              style={{ color: '#fff' }}
                              size={24}
                            />
                          ) : (
                            <SendIcon
                              style={{
                                marginLeft: 3,
                                transform: 'rotate(-45deg)',
                                marginBottom: 3,
                              }}
                            />
                          )}
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <div className='bpenquiry_details_right_action'>
                    <div className='confirm_reload'>
                      <div className=''></div>
                      <IconButton
                        style={{
                          padding: 0,
                        }}
                        aria-label='sync'
                        onClick={() => {
                          proposalsRefectch();
                          proposalRefectch();
                        }}>
                        <SyncIcon fontSize='medium' color={'primary'} />
                      </IconButton>
                    </div>

                    <div className='final_action'>
                      <p>
                        To book this operator, simply click the "Request to
                        Book" button below. Your booking will be confirmed once
                        the operator approves it, after which you’ll receive a
                        confirmation and an invoice. Please make sure to discuss
                        the preferred payment method directly with the operator.
                      </p>
                      <input
                        disabled={isPermitted ? false : true}
                        style={{
                          cursor: isPermitted ? 'pointer' : 'not-allowed',
                          opacity: isPermitted ? 1 : 0.5,
                        }}
                        onClick={async (e) => {
                          setIsSubmitting(true);
                          try {
                            // Update Proposal Conversations
                            await updateProposalOnBpenquiry({
                              _id: currentProposal,
                              conversations: {
                                sender: 'customer',
                                content: `Can you confirm this booking please?`,
                              },
                            });

                            // Finally Refetch Proposal
                            proposalRefectch();

                            setCuMessage('');

                            setIsSubmitting(false);
                          } catch (err) {
                            setIsSubmitting(false);
                            console.log(err);
                          }
                        }}
                        type='submit'
                        value={
                          isSubmitting ? `Please wait...` : `Request to Book`
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

          {!(bpenquiry?.proposals && bpenquiry.proposals.length > 0) && (
            <div className='no_offer'>
              <p>Please wait. Your operator will respond soon</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BusinessPageAllEnquiriesDetailsPage;
