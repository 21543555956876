import React, { useEffect, useState } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import './BusinessPageProfilePageSuccess.scss';
import { useNavigate } from 'react-router-dom';

const BusinessPageProfilePageSuccess: React.FC<{}> = (props) => {
  const navigate = useNavigate();

  const [domain, setDomain] = useState<string | undefined>();

  useEffect(() => {
    const urlObject = new URL(window.location.href);
    const domainRegex = new RegExp('businesspage\\/([^/]+)');
    const match = urlObject.pathname.match(domainRegex);

    if (match) {
      setDomain(match[1]);
    }
  }, []);

  return (
    <div className='enquiry_sent_wrapper'>
      <div className='enquiry_sent'>
        <div className='enquiry_sent_icon'>
          <CheckCircleRoundedIcon
            fontSize='large'
            style={{
              width: 60,
              height: 60,
              color: '#47C56C',
              border: '10px solid #D4F0E2',
              borderRadius: 100,
            }}
          />
        </div>
        <div className='enquiry_sent_content'>
          <h3>We've got you covered!</h3>

          <br />

          <div className='enquiry_sent_content_message'>
            <p>We've notified the operator of your trip.</p>

            <br />

            <p>
              You'll receive price very shortly. We'll email you once you get
              the quotation. You can also visit to 'All Enquiries' page at any
              time to get an update.
            </p>
          </div>
        </div>
        <div className='enquiry_sent_action'>
          <input
            type='submit'
            value='Go to All Enquiries'
            onClick={() => {
              navigate(`/businesspage/${domain}/all-enquiries`);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessPageProfilePageSuccess;
