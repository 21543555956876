/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import './BusinessPageProfilePage.scss';
import { gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserDetailsByDomain } from '../../hooks/gql/query/getByValue/useGetUserDetailsByDomain';
import { gBaseUrl, URLConfig } from '../../utils/config';
import { useSelector } from 'react-redux';
import ReviewListItem from '../../components/ListItem/ReviewListItem';
import { RootState } from '../../store/store';
import { useGetAllReviewsByCompanyId } from '../../hooks/gql/query/getAll/useGetAllReviewsByCompanyId';
import { Review } from '../../types/schema/review.types';
import { truncateText } from '../../utils/functions';
import {
  businesspage_default_images,
  businesspage_sections,
} from '../../utils/utils';
import CustomerInfoForm from '../../components/Utils/CustomerInfoForm';
import BookingForm from '../../components/Utils/BookingForm';
import GoogleMapsDisplay from '../../components/Utils/GoogleMapsDisplay';

const GET_USER_DETAILS_BY_DOMAIN = gql`
  query GetUserDetailsByDomain($input: TextInput!) {
    getUserDetailsByDomain(input: $input) {
      _id
      photo
      isPhotoVisible
      vehiclePictures
      isVPVisible
      company_name
      domain_name
      contact_name
      referral_code
      email
      phone
      business_address {
        location_name
        cords
      }
    }
  }
`;

const GET_REVIEWS_BY_PAGE_BY_COMPID = gql`
  query GetAllReviewsByCompanyIdByPage($input: PaginationWithIdInput!) {
    getAllReviewsByCompanyIdByPage(input: $input) {
      _id
      review_by {
        _id
        name
      }
      review_to {
        _id
        contact_name
        referral_code
        photo
        isPhotoVisible
      }
      message
      rating
      createdAt
    }
  }
`;

const BusinessPageProfilePage: React.FC<{}> = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { slug } = useParams();
  const [isSticky, setIsSticky] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [page_number, setPage_number] = useState(1);
  const [isBookingForm, setIsBookingForm] = useState(isAuthenticated);
  const [showMap, setShowMap] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    setIsSticky(scrollY > 50);

    let closestSection = null;
    let closestOffset = Infinity;

    for (const sectionId of businesspage_sections) {
      const section = document.getElementById(sectionId);
      if (section) {
        const { top } = section.getBoundingClientRect();
        const offset = Math.abs(top);

        if (offset < closestOffset) {
          closestSection = sectionId;
          closestOffset = offset;
        }
      }
    }

    setActiveSection(`#${closestSection}`);
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 80, // Adjust based on your sticky menu height
        behavior: 'smooth',
      });
    }
  };

  const {
    data: user_info,
    loading: user_infoLoading,
    refetch: user_infoRefetch,
    error: user_infoError,
  } = useGetUserDetailsByDomain(GET_USER_DETAILS_BY_DOMAIN, {
    text: slug || '',
  });

  const {
    data: reviews,
    error: reviewsError,
    loading: reviewsLoading,
    refetch: reviewsRefectch,
  } = useGetAllReviewsByCompanyId(GET_REVIEWS_BY_PAGE_BY_COMPID, {
    _id: user_info?._id || '65a88d3c1464a102af9d1704',
    page: page_number,
    item_per_page: 3,
  });

  useEffect(() => {
    reviewsRefectch();
  }, [user_info?._id]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMap(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const vehiclePictures = user_info?.vehiclePictures;
  const descriptionText = `Thank you for visiting ${user_info?.company_name}. It would be my pleasure to
  serve you. Rest assured, all your transportation needs will be fully taken care of, with exceptional customer service every step of the way. By submitting your requirements through the booking form below, I can ensure that your reservation is handled with the utmost care and attention to detail.`;
  const bp_images =
    user_info?.isVPVisible && vehiclePictures && vehiclePictures.length > 2
      ? vehiclePictures.map((vp) => `${gBaseUrl}/photos/vehiclePictures/${vp}`)
      : businesspage_default_images;

  if (user_infoLoading) {
    return (
      <div className='loading_section'>
        <p>Please wait...</p>
      </div>
    );
  }

  if (!user_info) {
    return (
      <div className='notfound_section'>
        <p>Oops! No business page found with the domain name given</p>
      </div>
    );
  }

  return (
    <>
      <div className='banner_section'>
        <div className='banner_image'>
          <img className='bannerimg' src={`${bp_images[0]}`} alt='' />
        </div>
        <div className='banner_description'>
          <div className='banner_description_logo'>
            <div className='banner_description_logo-left'>
              {user_info && user_info.isPhotoVisible && user_info.photo ? (
                <img
                  className=''
                  src={`${gBaseUrl}/photos/avatars/${user_info?.photo}`}
                  alt='User Avatar'
                />
              ) : (
                <img
                  className=''
                  src={`${URLConfig.customerWebUrl}/images/main_logo.png`}
                  alt='Fully Booked Avatar'
                />
              )}
              <div className='brand_desc'>
                <h2>{user_info?.company_name}</h2>
                <p>{user_info?.contact_name}</p>
              </div>
            </div>
            <div className='banner_description_logo-right'>
              <a
                onClick={() => scrollToSection('form_section')}
                className='booking_form'>
                Booking Form
              </a>
            </div>
          </div>
          <div className='banner_description_text'>
            <p>
              {isExpanded
                ? descriptionText
                : truncateText(descriptionText, 200)}
            </p>
            <span
              className='see-more'
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}>
              {isExpanded ? 'See Less' : 'See More'}
            </span>
          </div>
        </div>
      </div>

      <div className={`menu_section ${isSticky ? 'sticky' : ''}`}>
        <div className={`business_page_nav`}>
          <ul>
            <li className={activeSection === '#image_section' ? 'active' : ''}>
              <a onClick={() => scrollToSection('image_section')}>All</a>
            </li>
            <li className={activeSection === '#form_section' ? 'active' : ''}>
              <a onClick={() => scrollToSection('form_section')}>About Us</a>
            </li>
            <li className={activeSection === '#review_section' ? 'active' : ''}>
              <a onClick={() => scrollToSection('review_section')}>Reviews</a>
            </li>
            <li
              className={activeSection === '#location_section' ? 'active' : ''}>
              <a onClick={() => scrollToSection('location_section')}>Find Us</a>
            </li>
          </ul>
        </div>
      </div>

      <div className='image_section' id='image_section'>
        <div className='slider_images'>
          {bp_images.map((img) => {
            return (
              <div className='slider_images_item' key={img}>
                <img src={`${img}`} alt='' />
              </div>
            );
          })}
        </div>
      </div>

      <div className='form_section' id='form_section'>
        <div className='bp_booking_form_section'>
          <div className='bp_booking_form_section_form'>
            <div className='bp_booking_form_section_form_content'>
              <div className='bp_booking_form_section_form_content_greet'>
                <div
                  className='customer_section'
                  onClick={() => {
                    setIsBookingForm(false);
                  }}>
                  <p style={{ color: isBookingForm ? '#969696' : '#0065FF' }}>
                    Customer Details
                  </p>
                </div>
                <div
                  className='booking_section'
                  onClick={() => {
                    if (isAuthenticated) {
                      setIsBookingForm(true);
                    } else {
                      alert('Please submit your customer details first.');
                    }
                  }}>
                  <p style={{ color: isBookingForm ? '#0065FF' : '#969696' }}>
                    Trip Details
                  </p>
                </div>
              </div>

              {isBookingForm && user_info && (
                <BookingForm
                  company_id={user_info._id}
                  domain={user_info.domain_name}
                />
              )}

              {!isBookingForm && (
                <CustomerInfoForm
                  onSetBookingForm={(isSet) => {
                    setIsBookingForm(isSet);
                  }}
                />
              )}
            </div>
          </div>
          <div className='bp_booking_form_section_ad'>
            <h1>{user_info?.company_name}</h1>

            <p>
              {user_info?.company_name} is the top provider of private hire
              services in the country. With years of experience, we are
              committed to offering safe, reliable, and eco-friendly
              transportation nationwide.
            </p>

            <img
              className='bus_img'
              style={{
                cursor: 'pointer',
              }}
              src={`${bp_images[2]}`}
              alt='Bus'
            />
          </div>
        </div>
      </div>

      {reviews && reviews.length > 0 && (
        <div className='review_section' id='review_section'>
          <div className='bp_review_section'>
            <h2>What Our Customers Say</h2>
            <div className='bp_review_section_items'>
              {reviews.map((rv: Review) => {
                return (
                  <ReviewListItem
                    _id={rv._id}
                    key={rv._id}
                    createdAt={rv.createdAt}
                    rating={rv.rating}
                    message={rv.message}
                    contact_name={rv.review_to.contact_name}
                    referral_code={rv.review_to.referral_code}
                    photo={rv.review_to.photo}
                    isPhotoVisible={rv.review_to.isPhotoVisible}
                    review_by={{
                      name: rv.review_by.name,
                    }}
                  />
                );
              })}
            </div>
            <div className='bp_review_section_action'>
              <input
                type='submit'
                value={reviewsLoading ? 'Please wait...' : 'View More Reviews'}
                onClick={() => {
                  setPage_number((pn) => pn + 1);
                  reviewsRefectch();
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div className='location_section' id='location_section'>
        <div className='location_section_desc'>
          <h2>Here We Are</h2>
          <p>
            Visit us at hour business address or simply use Google Maps for Easy
          </p>
        </div>
        <div className='location_section_content'>
          <div className='location_section_content-map'>
            {showMap && user_info && user_info?.business_address && (
              <GoogleMapsDisplay
                business_address={user_info.business_address}
              />
            )}
          </div>
          <div className='location_section_content-wh'>
            <div className='working_hours'>
              <h2>Working Hours</h2>

              <table>
                <tbody>
                  <tr>
                    <td className='day'>Sunday</td>
                    <td>07:00 AM - 18:00 PM</td>
                  </tr>
                  <tr>
                    <td className='day'>Monday</td>
                    <td>07:00 AM - 18:00 PM</td>
                  </tr>
                  <tr>
                    <td className='day'>Tuesday</td>
                    <td>07:00 AM - 18:00 PM</td>
                  </tr>
                  <tr>
                    <td className='day'>Wednesday</td>
                    <td>07:00 AM - 18:00 PM</td>
                  </tr>
                  <tr>
                    <td className='day'>Thursday</td>
                    <td>07:00 AM - 18:00 PM</td>
                  </tr>
                  <tr>
                    <td className='day'>Friday</td>
                    <td>07:00 AM - 18:00 PM</td>
                  </tr>
                  <tr>
                    <td className='day'>Saturday</td>
                    <td>07:00 AM - 18:00 PM</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='contact_details'>
              <h2>Contact Details</h2>

              <table>
                <tbody>
                  <tr>
                    <td className=''>Phone Number</td>
                    <td>{user_info?.phone}</td>
                  </tr>
                  <tr>
                    <td className=''>Email Address</td>
                    <td>{user_info?.email}</td>
                  </tr>

                  <tr>
                    <td className=''>Business Address</td>
                    <td>{user_info?.business_address.location_name}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessPageProfilePage;
