import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BusinessPage: React.FC = () => {
  const [seconds, setSeconds] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      navigate('/');
    }
  }, [seconds, navigate]);

  return (
    <div>
      <p style={{ textAlign: 'center', margin: '50px 0' }}>
        Please use a valid business domain. You will be redirected in {seconds}{' '}
        seconds...
      </p>
    </div>
  );
};

export default BusinessPage;
