/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './BusinessPageFooter.scss';
import moment from 'moment';
import { URLConfig } from '../../utils/config';

const BusinessPageFooter: React.FC<{
  domain?: string;
}> = ({ domain = new URL(URLConfig.customerWebUrl).hostname }) => {
  return (
    <footer className='bp_footer_wrapper'>
      <div className='bp_footer_content'>
        <div className='bp_copyright_content'>
          <p>&copy; {moment(new Date()).format('YYYY')} All Right Reserved</p>

          <p>{domain}</p>
        </div>
      </div>
    </footer>
  );
};

export default BusinessPageFooter;
