import {
  Select as MUISelect,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  MenuItem,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Colors from '../../constants/Colors';
import { URLConfig } from '../../utils/config';
import {
  travelling_reason_arr,
  vahicle_types_arr,
  luggage_type_arr,
} from '../../utils/data';
import CustomButton from '../UI/CustomButton';
import Autocomplete from 'react-google-autocomplete';
import { RootState } from '../../store/store';
import { AddBpenquiryInput } from '../../types/schema/bpenquiry.types';
import { useAddBpenquiry } from '../../hooks/gql/mutation/create/useAddBpenquiry';

const BookingForm: React.FC<{ company_id: string; domain: string }> = ({
  company_id,
  domain,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const [checked, setChecked] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { addBpenquiry } = useAddBpenquiry();

  // FORM STATES STARTS

  // Trip Details
  const [pickupLocation, setPickupLocation] = useState<{
    location_name: string;
    cords: number[];
  }>({ location_name: '', cords: [] });
  const [pickupDateTime, setPickupDateTime] = useState<string>('');
  const [pickupSNote, setPickupSNote] = useState<string>('');
  const [dropLocation, setDropLocation] = useState<{
    location_name: string;
    cords: number[];
  }>({ location_name: '', cords: [] });
  const [dropSNote, setDropSNote] = useState<string>('');
  const [returnDateTime, setReturnDateTime] = useState<string>('');
  const [vehicleType, setVehicleType] = useState('0');
  const [messageOptional, setMessageOptional] = useState('');
  const [travellingReason, setTravellingReason] = useState('0');
  const [passengerCount, setPassengerCount] = useState('');
  const [luggageType, setLuggageType] = useState('0');
  const [vehicleCount, setVehicleCount] = useState('');
  // FORM STATES ENDS

  return (
    <>
      <div className='bp_booking_form_section_form_content_way'>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onClick={() => {
                setChecked((prev) => !prev);
              }}
            />
          }
          label='One Way Trip'
        />
        <FormControlLabel
          control={<Checkbox checked={!checked} />}
          label='Round Trip'
          onClick={() => {
            setChecked((prev) => !prev);
          }}
        />
      </div>
      <div className='bp_booking_form_section_form_content_linp'>
        {pickupSNote && (
          <p style={{ color: 'red', margin: 0 }}>{pickupSNote}</p>
        )}
        <Autocomplete
          style={{
            padding: '8.5px 10px',
            borderColor: Colors.lightGrey,
            outline: 'none',
            boxShadow: 'none',
            borderRadius: 5,
            borderWidth: 1,
            fontFamily: 'DM Sans',
            fontSize: 16,
          }}
          options={{
            types: ['geocode'],
            componentRestrictions: { country: 'uk' },
          }}
          onChange={() => {
            setPickupLocation({ location_name: '', cords: [] });
          }}
          onBlur={() => {
            if (pickupLocation.cords.length === 0) {
              setPickupSNote(
                'Please provide the complete address then select it from the dropdown options to ensure you receive the best possible price.'
              );
            }
          }}
          placeholder='Pickup address'
          apiKey={URLConfig.mapsGeocodingAPIKey}
          onPlaceSelected={(place) => {
            if (place.geometry?.location && place.formatted_address) {
              const locationDetails = {
                location_name: place.formatted_address,
                cords: [
                  place.geometry.location.lng(),
                  place.geometry.location.lat(),
                ],
              };

              setPickupLocation(locationDetails);
              setPickupSNote('');
            } else {
              alert('Please select a valid  address');
            }
          }}
        />

        {dropSNote && <p style={{ color: 'red', margin: 0 }}>{dropSNote}</p>}

        <Autocomplete
          style={{
            padding: '8.5px 10px',
            borderColor: Colors.lightGrey,
            outline: 'none',
            boxShadow: 'none',
            borderRadius: 5,
            borderWidth: 1,
            fontFamily: 'DM Sans',
            fontSize: 16,
          }}
          options={{
            types: ['geocode'],
            componentRestrictions: { country: 'uk' },
          }}
          onChange={() => {
            setDropLocation({ location_name: '', cords: [] });
          }}
          onBlur={() => {
            if (pickupLocation.cords.length === 0) {
              setPickupSNote(
                'Please provide the complete address then select it from the dropdown options to ensure you receive the best possible price.'
              );
            }
          }}
          placeholder='Destination address'
          apiKey={URLConfig.mapsGeocodingAPIKey}
          onPlaceSelected={(place) => {
            if (place.geometry?.location && place.formatted_address) {
              const locationDetails = {
                location_name: place.formatted_address,
                cords: [
                  place.geometry.location.lng(),
                  place.geometry.location.lat(),
                ],
              };

              setDropLocation(locationDetails);
              setDropSNote('');
            } else {
              alert('Please select a valid  address');
            }
          }}
        />
      </div>
      <div className='bp_booking_form_section_form_content_drdt'>
        <DateTimePicker
          label='Departure Date Time'
          format='LLL'
          onChange={(val: any) => {
            setPickupDateTime(moment(val).format());
          }}
          minDateTime={moment(new Date())}
        />
        {!checked && (
          <DateTimePicker
            label='Return Date Time'
            format='LLL'
            onChange={(val: any) => {
              setReturnDateTime(moment(val).format());
            }}
            minDateTime={moment(pickupDateTime)}
          />
        )}
      </div>

      <div className='bp_booking_form_section_form_content_pr'>
        <TextField
          value={passengerCount}
          onChange={(val) => {
            setPassengerCount(val.target.value);
          }}
          id='passenger_count'
          label='Number of Passengers'
          variant='outlined'
          type='number'
        />
        <FormControl style={{}}>
          <MUISelect
            value={travellingReason}
            id='travelling-reason-select'
            onChange={(event: any) => {
              setTravellingReason(event.target.value);
            }}>
            <MenuItem value={'0'}>Travelling reason</MenuItem>
            {travelling_reason_arr.map((tr) => {
              return (
                <MenuItem key={tr.value} value={tr.value}>
                  {tr.label}
                </MenuItem>
              );
            })}
          </MUISelect>
        </FormControl>
      </div>

      <div className='bp_booking_form_section_form_content_vehicle'>
        <FormControl style={{}}>
          <MUISelect
            value={vehicleType}
            id='vehilce-select'
            onChange={(event: any) => {
              setVehicleType(event.target.value);
            }}>
            <MenuItem value={'0'}>Select vehicle capacity you need</MenuItem>
            {vahicle_types_arr.map((va) => {
              return (
                <MenuItem key={va.value} value={va.value}>
                  {va.label}
                </MenuItem>
              );
            })}
          </MUISelect>
        </FormControl>
      </div>

      <div className='bp_booking_form_section_form_content_vl'>
        <TextField
          value={vehicleCount}
          onChange={(val) => {
            setVehicleCount(val.target.value);
          }}
          id='vehicle_count'
          label='Number of Vehicle'
          variant='outlined'
          type='number'
        />
        <FormControl style={{}}>
          <MUISelect
            value={luggageType}
            id='luggage-select'
            onChange={(event: any) => {
              setLuggageType(event.target.value);
            }}>
            <MenuItem value={'0'}>Luggage Type</MenuItem>
            {luggage_type_arr.map((lr) => {
              return (
                <MenuItem key={lr.value} value={lr.value}>
                  {lr.label}
                </MenuItem>
              );
            })}
          </MUISelect>
        </FormControl>
      </div>

      <div className='bp_booking_form_section_form_content_message'>
        <textarea
          value={messageOptional}
          onChange={(val) => {
            setMessageOptional(val.target.value);
          }}
          name='message_optional'
          id='message_optional'
          cols={36}
          rows={7}
          style={{}}
          placeholder={`Please use this section to add any additional requirements you may have. For Example, we need additional drop offs on the return journey.`}></textarea>
      </div>
      <div className='bp_booking_form_section_form_content_action'>
        <CustomButton
          title={isSubmitting ? `Please wait...` : 'Send Enquiry to Operator'}
          isLoading={isSubmitting}
          isDisabled={
            !(
              pickupLocation.cords.length > 0 &&
              dropLocation.cords.length > 0 &&
              !!pickupDateTime &&
              !!passengerCount &&
              !!vehicleCount &&
              luggageType.length > 2 &&
              travellingReason.length > 2 &&
              vehicleType.length > 2
            )
          }
          onClick={async () => {
            setIsSubmitting(true);

            const bp_enquiry_data: AddBpenquiryInput = {
              company_ref: company_id,
              customer_ref: user._id,
              trip_info: {
                tripType: checked ? 'one_way_trip' : 'round_trip',
                pickup_location: pickupLocation,
                destination: dropLocation,
                pickup_datetime: pickupDateTime,
                return_datetime: returnDateTime || undefined,
                passenger_count: +passengerCount,
                note: messageOptional || '',
                travelling_reason: travellingReason,
                vehicle_type: vehicleType,
                luggage_type: luggageType,
                vehicle_count: +vehicleCount,
              },
            };

            try {
              const res = await addBpenquiry(bp_enquiry_data);
              const bpenquiry = res.addBpenquiry;
              if (bpenquiry) {
                setTimeout(() => {
                  navigate(`/businesspage/${domain}/enquiry-sent`);
                }, 100);
              } else {
                throw new Error(JSON.stringify(res.error));
              }
            } catch (err) {
              console.log(err);
            }

            setIsSubmitting(false);
          }}
        />
      </div>
    </>
  );
};

export default BookingForm;
