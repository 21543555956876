import moment from 'moment';
import {
  emailAndDomainRegex,
  getCommonWordsRegEx,
  labelsRating,
  spelledOutNumbersRegex,
  ukPhoneRegex,
} from './data';
import { ConversationsObjType } from '../types/schema/utils.types';

export const beautifyString = (inputString: string) => {
  // Remove the leading slash and split the string into words
  const words = inputString.replace(/^\//, '').split('-');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words back together with spaces
  const beautifiedString = capitalizedWords.join(' ');

  return beautifiedString;
};

export const getLabelText = (value: number) => {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labelsRating[value]}`;
};

export const formatString = (slug?: string) => {
  if (!slug) {
    return '';
  }

  const formattedSlug = slug
    .replace(/[-_]+/g, ' ')
    .replace(
      /\b(?!and\b)\w+/g,
      (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase()
    )
    .replace(/And/g, 'and');

  return formattedSlug;
};

export const getRatingLabel = (value: number): string => {
  if (value <= 0.5) {
    return labelsRating[0.5];
  } else if (value <= 1) {
    return labelsRating[1];
  } else if (value <= 1.5) {
    return labelsRating[1.5];
  } else if (value <= 2) {
    return labelsRating[2];
  } else if (value <= 2.5) {
    return labelsRating[2.5];
  } else if (value <= 3) {
    return labelsRating[3];
  } else if (value <= 3.5) {
    return labelsRating[3.5];
  } else if (value <= 4) {
    return labelsRating[4];
  } else if (value <= 4.5) {
    return labelsRating[4.5];
  } else if (value <= 5) {
    return labelsRating[5];
  }

  // Default case if the value is out of the specified range
  return 'Unknown';
};

export const getNameAvatar = (name: string) => {
  const avtrArr = name?.match(/\b(\w)/g)?.slice(0, 2);
  if (!avtrArr) return name;
  const avtr = avtrArr.slice(0, 2).join('').toUpperCase();
  return avtr;
};

export const getVehicleType = (input: string): string => {
  if (!input) return '';

  const parts = input.replace('_seater', '').split('_');
  const firstPart = parts[0];

  if (firstPart.includes('-')) {
    const [lower, upper] = firstPart.split('-');
    return `${parts
      .slice(1, parts.length)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ')}: ${lower} - ${upper} Seats`;
  } else {
    return `${parts
      .slice(1, parts.length)
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ')}: ${firstPart} Seats`;
  }
};
export const formatMessages = (messages: ConversationsObjType[]) => {
  // Function to format date as "DD MMM, YYYY"
  const formatDate = (dateString: Date | string) => {
    return moment(dateString).format('DD MMM, YYYY');
  };

  // Group messages by date using Map()
  const messageMap = new Map<string, ConversationsObjType[]>();

  messages.forEach((message) => {
    const formattedDate = formatDate(message.sentAt);
    if (!messageMap.has(formattedDate)) {
      messageMap.set(formattedDate, []);
    }

    messageMap.get(formattedDate)?.push(message);
  });

  const formattedmessages = [];
  for (const [date, messages] of messageMap) {
    formattedmessages.push(date);
    formattedmessages.push(...messages);
  }

  return formattedmessages;
};

export const detectSensitiveInformation = (text: string, words: string[]) => {
  if (text.match(ukPhoneRegex)) return true;
  if (text.match(emailAndDomainRegex)) return true;
  if (text.match(spelledOutNumbersRegex)) return true;
  if (text.match(getCommonWordsRegEx(words))) return true;

  return false;
};

export const getAmountWithCurrency = (value: number, isCent?: boolean) => {
  return `£${(value / (isCent ? 100 : 1)).toFixed(2)}`;
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + '...';
};
